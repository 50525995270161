import React, { useEffect } from "react";
import marketingDigitalIcon from "../../assets/icons/marketing_digital_icon.png";
import developmentIcon from "../../assets/icons/development_icon.png";
import mobileIcon from "../../assets/icons/mobile_icon.png";
import webIcon from "../../assets/icons/web_icon.png";
import conseilIcon from "../../assets/icons/conseil_icon.png";
import linesImage from "../../assets/images/lines_image.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// import Aos from "aos";
// import "aos/dist/aos.css";

const CustomPrevArrow = (props) => (
  <div
    className=" bg-[#FF0000] w-max absolute top-1/2 rounded-full p-1 cursor-pointer left-0"
    style={{ ...props.style, zIndex: 1 }} // Customize the position
    onClick={props.onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-5 h-5 text-white "
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5 8.25 12l7.5-7.5"
      />
    </svg>
  </div>
);

const listServices = [
  {
    image: marketingDigitalIcon,
    title: "Marketing Digital",
    description: [
      "Expert en marketing digital, Aynnaka propose une gamme complète de services pour booster votre présence en ligne.",
      "De l’élaboration de votre stratégie digitale à la création de contenu engageant.",
    ],
  },
  {
    image: developmentIcon,
    title: "Développement de logiciel",
    description: [
      "Aynnaka, une agence digitale, aide votre entreprise à prospérer avec des solutions de développement de logiciels informatiques de qualité.",
      "Nous créons des logiciels qui améliorent l'expérience client, accélèrent les processus de vente et maximisant vos profits.",
    ],
  },
  {
    image: mobileIcon,
    title: "Application mobile",
    description: [
      "Le développement d'une application mobile est un moyen clé de se rapprocher de vos clients.",
      "Aynnaka est votre partenaire idéal pour créer des applications ergonomiques, performantes et intégrées.",
    ],
  },
  {
    image: webIcon,
    title: "Création des sites web",
    description: [
      "Optimisez votre présence en ligne avec les experts en création de sites web de Aynnaka. ",
      "Nous vous accompagnons tout au long du processus pour vous garantir un site web à la hauteur de vos attentes.",
    ],
  },
  {
    image: conseilIcon,
    title: "Conseil IT",
    description: [
      "Transformez votre entreprise avec succès grâce au service conseil IT de Aynnaka. ",
      "Contactez notre équipe de consultants informatiques qui vous accompagneront pour développer une stratégie digitale sur mesure.",
    ],
  },
];

const CustomNextArrow = (props) => (
  <div
    className=" bg-[#FF0000] w-max absolute top-1/2 rounded-full p-1 cursor-pointer right-0"
    style={{ ...props.style, zIndex: 1 }} // Customize the position
    onClick={props.onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-5 h-5 text-white "
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  </div>
);
function Services() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024, // medium screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // centerMode: true, // Enable center mode
    // variableWidth: true, // Allow variable width for each slide
  };

  useEffect(() => {
    // Aos.init();
  }, []);

  return (
    <div className="w-screen min-h-max relative" id="our-services">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        className="container mx-auto w-full relative"
      >
        <div className="text-center text-[#140083] text-xl">Découvrez</div>
        <div className="text-center text-[#FF0000] font-bold text-4xl mt-2 mb-5">
          Nos services
        </div>
        <div className="  h-16"></div>
        {/* list */}
        <div className="relative mx-10 ">
          <Slider {...settings}>
            {listServices.map((service, index) => (
              <div className={`${index % 2 !== 0 ? "md:mt-10 mt-0" : "mt-0"}`}>
                <div
                  key={index}
                  className="bg-[#140083] rounded-2xl px-6 md:py-16 py-10 mx-5 "
                >
                  {/* icon */}
                  <img className="mb-3 mx-auto" src={service.image} alt="" />
                  {/* title */}
                  <div className="font-bold text-xl text-white text-center my-2">
                    {service.title}
                  </div>
                  {/* desc */}
                  <div className=" my-4">
                    {service.description.map((item) => (
                      <div className="text-white md:text-base text-sm md:text-justify text-center">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className=" md:h-24 h-16"></div>
        <div className=" absolute top-0 z-0 w-full flex justify-center items-start">
          <img src={linesImage} alt="" className="" />
        </div>
      </div>
    </div>
  );
}

export default Services;
