import React from "react";

import logo from "../assets/icons/aynnaka_logo.png";
import bgFooter from "../assets/images/bg-footer.png";
import wavyFooter from "../assets/images/wavy_left.png";
import fbIcon from "../assets/icons/icon_facebook.png";
import instaIcon from "../assets/icons/icon_instagram.png";
import linkIcon from "../assets/icons/icon_linkedin.png";

function Footer() {
  return (
    <div className=" mt-24 ">
      <div className="w-full relative">
        <div className="md:block hidden">
          <img src={bgFooter} alt="" className="w-full" />
        </div>
        <div className="md:hidden block">
          <img src={wavyFooter} alt="" className="w-full" />
        </div>
        <div className="flex md:flex-row flex-col bg-[#140083]  z-30">
          <div className="md:w-2/3 w-full relative pt-10">
            <div className="w-full flex text-sm text-white md:items-start items-center  justify-center  ">
              <div className="mx-5 cursor-pointer group">
                <a href="/">Accueil</a>
              </div>
              <div className="mx-5 cursor-pointer group">
                <a href="/#about-us">A propos</a>
              </div>
              <div className="mx-5 cursor-pointer group">
                <a href="/#our-services">Nos services</a>
              </div>
              <div className="mx-5 cursor-pointer group">
                <a href="/#temoignages">Témoignages</a>
              </div>
            </div>
            <div className=" flex md:justify-end justify-center flex-col my-5  md:float-right md:w-max w-full ">
              <div className="flex flex-row  md:justify-start justify-center  mx-5  ">
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.linkedin.com/company/aynnaka/"
                >
                  <img className="mx-2 " src={linkIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.facebook.com/profile.php?id=100076194249029"
                >
                  <img className="mx-2 " src={fbIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.instagram.com/aynnaka/"
                >
                  <img className="mx-2 " src={instaIcon} alt="" />
                </a>
              </div>
              <div className="flex flex-row mx-5 my-5 text-sm text-white md:justify-start justify-center ">
                <div className=" mx-2 cursor-pointer group">
                  <a href="/#">Politique de confidentialité</a>
                </div>
                <div className="mx-5 cursor-pointer group">
                  <a href="/">CGV</a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full relative bg-white py-5 md:text-left text-center">
            <img src={logo} alt="" className="md:mx-5 mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
