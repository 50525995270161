import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import confianceImage from "../../assets/images/confience_image.png";

const CustomPrevArrow = (props) => (
  <div
    className=" bg-[#FF0000] w-max absolute top-1/2 rounded-full p-1 cursor-pointer left-0"
    style={{ ...props.style, zIndex: 1 }} // Customize the position
    onClick={props.onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-5 h-5 text-white "
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5 8.25 12l7.5-7.5"
      />
    </svg>
  </div>
);

const CustomNextArrow = (props) => (
  <div
    className=" bg-[#FF0000] w-max absolute top-1/2 rounded-full p-1 cursor-pointer right-0"
    style={{ ...props.style, zIndex: 1 }} // Customize the position
    onClick={props.onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-5 h-5 text-white "
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  </div>
);

const listConfiance = [
  {
    user: "Mohamed B.",
    content:
      "Je suis absolument ravi du travail accompli par Aynnaka. Ils sont professionnels, efficaces et créatifs. Je recommande fortement leur service à toutes les entreprises en quête de succès sur le web.",
  },
  {
    user: "Mohamed B.",
    content:
      "Je suis absolument ravi du travail accompli par Aynnaka. Ils sont professionnels, efficaces et créatifs. Je recommande fortement leur service à toutes les entreprises en quête de succès sur le web.",
  },
  {
    user: "Mohamed B.",
    content:
      "Je suis absolument ravi du travail accompli par Aynnaka. Ils sont professionnels, efficaces et créatifs. Je recommande fortement leur service à toutes les entreprises en quête de succès sur le web.",
  },
];

function Confiance() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  return (
    <div className="w-screen min-h-max relative" id="temoignages">
      <div className="container mx-auto w-full">
        <div className="text-center text-[#140083] text-xl">
          Ils nous ont fait
        </div>
        <div className="text-center text-[#FF0000] font-bold text-4xl mt-2 mb-5">
          Confiance
        </div>
        {/* items */}

        <div className="relative md:w-4/5 w-full mx-auto min-h-max mt-5 ">
          <div
            className="relative w-full bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: "url(" + confianceImage + ")" }}
          >
            <div className="h-16"></div>
            {}
            <Slider {...settings} className="md:my-24 my-12">
              {/* Your carousel items */}
              {listConfiance.map((item, index) => (
                <div>
                  <div
                    key={index}
                    className="bg-[#140083] rounded-2xl md:px-6 px-3 md:py-12 py-6 mx-5 "
                  >
                    {/* desc */}
                    <div className="text-white md:text-base text-sm  text-center my-3">
                      {item.content}
                    </div>
                    {/*  */}
                    <div className="text-white font-bold text-center my-3">
                      {item.user}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className=" md:h-24 h-16"></div>
          </div>
        </div>

        {/* <div className=" absolute md:top-[3%] top-1/4 -z-20 w-full flex justify-center items-center">
          <img src={confianceImage} alt="" className="md:w-1/2 " />
        </div> */}
      </div>
    </div>
  );
}

export default Confiance;
