import React, { useEffect, useState } from "react";

import backAynnaka from "../assets/images/background_aynnaka.png";

import Hero from "./widgets/Hero";
import Header from "../components/Header";
import About from "./widgets/About";
import Services from "./widgets/Services";
import Choisir from "./widgets/Choisir";
import Confiance from "./widgets/Confiance";

import contactImage from "../assets/images/contact_image.png";
import Footer from "../components/Footer";

// import Aos from "aos";
// import "aos/dist/aos.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function HomeScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    // Aos.init();
  });
  return (
    <div
      className="w-screen min-h-max bg-cover bg-no-repeat pt-5 "
      style={{ backgroundImage: "url(" + backAynnaka + ")" }}
    >
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* loading */}
      {isLoading && (
        <div className="h-screen w-screen fixed z-50 bg-[#0000005c] flex items-center justify-center scr">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      )}
      {/* header */}
      <Header />
      {/* hero */}
      <Hero />
      {/* about me */}
      <About />
      {/* services */}
      <Services />
      {/* Nous choisir */}
      <Choisir />
      {/* Confiance */}
      <Confiance />
      {/* contact */}
      <div
        className="w-screen flex md:flex-row flex-col my-16 justify-center items-stretch shadow "
        id="contact-us"
      >
        <div
          data-aos="fade-right"
          className="md:w-1/2 w-full bg-[#140083] flex justify-end items-end "
        >
          <img className="" src={contactImage} alt="" />
        </div>
        <div
          data-aos="fade-left"
          className="md:w-1/2 w-full  flex flex-col justify-start  px-10 my-10 "
        >
          <div className="text-3xl  my-3 text-[#140083] font-bold">
            Let’s Connect !
          </div>

          <div className="flex flex-row my-1">
            <div className="w-1/2 md:px-5 mx-2">
              <div className="rounded shadow flex flex-col justify-center items-center p-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-8 h-8 text-[#FF0000]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                    />
                  </svg>
                </div>
                <div className="my-2 font-bold text-sm text-[#140083] ">
                  (+212) 661-137043
                </div>
              </div>
            </div>
            {/*  */}
            <div className="w-1/2 md:px-5 mx-2">
              <a
                href="mailto:info@aynnaka.ma"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="rounded shadow flex flex-col justify-center items-center p-4">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-8 h-8 text-[#FF0000]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                  </div>
                  <div className="my-2 font-bold text-sm text-[#140083] ">
                    info@aynnaka.ma
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* mail */}
          <div className="flex flex-row my-1">
            <div className="w-full md:px-5 mx-2">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg9MgYIAhAuGEDSAQczNDlqMGoxqAIAsAIA&um=1&ie=UTF-8&fb=1&gl=ma&sa=X&geocode=Ka9L-WCG768NMRjvgY11zWTP&daddr=9,+Etage+3,+No,+Porte,+166+Bd+Abdelkrim+Al+Khattabi,+Marrakech+40000"
              >
                <div className="rounded shadow flex flex-row justify-center items-center p-4">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-8 h-8 text-[#FF0000]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                  </div>
                  <div className="my-2 mx-2 font-bold text-sm text-[#140083] ">
                    9, Etage 3, No, Porte, 166 Bd Abdelkrim Al Khattabi,
                    Marrakech 40000
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className=" text-[#140083] my-3">
            Contactez-nous dès maintenant pour découvrir comment Aynnaka peut
            vous aider à atteindre vos objectifs.
          </div>

          <div className="flex md:flex-row flex-col md:my-3">
            <input
              type="text"
              value={userName}
              onChange={(v) => setUserName(v.target.value)}
              placeholder="Votre nom et prénom"
              className="md:w-1/3 w-full px-5 py-2 my-2 text-[#140083] bg-gradient-to-b from-zinc-300 to-white rounded-2xl shadow border-none focus:outline-none"
            />
            <div className="w-5"></div>
            <input
              type="text"
              value={mail}
              onChange={(v) => setMail(v.target.value)}
              placeholder="Votre adresse mail"
              className="md:w-2/3 w-full px-5 py-2 my-2  text-[#140083] bg-gradient-to-b from-zinc-300 to-white rounded-2xl shadow border-none focus:outline-none"
            />
          </div>
          <div className="md:hidden flex flex-col mt-2 mb-10">
            <textarea
              type="text"
              rows={4}
              value={message}
              onChange={(v) => setMessage(v.target.value)}
              placeholder="Votre message"
              className="md:w-1/3 w-full px-5 py-2 my-2 text-[#140083] bg-gradient-to-b from-zinc-300 to-white rounded-2xl shadow border-none focus:outline-none"
            />
            <div
              onClick={async () => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isValid = emailPattern.test(mail);
                if (mail === "") {
                  toast.error("Veuillez entrer votre email");
                } else if (userName === "") {
                  toast.error("S'il vous plaît entrez votre nom");
                } else if (message === "") {
                  toast.error("Veuillez entrer votre message");
                } else if (!isValid) {
                  toast.error("Veuillez entrer votre email correct");
                } else {
                  // toast.success("nice")
                  setIsLoading(true);

                  try {
                    // await axios.post(
                    // "http://localhost:3001/send-email/",
                    // {
                    await axios.post(
                      "https://mailapi.aynnaka.ma/send-email/",
                      {
                        mail: mail,
                        username: userName,
                        message: message,
                      },
                      {
                        withCredentials: true, // Ensure CORS is enabled
                      }
                    );
                    setIsLoading(false);
                    toast.success("Votre message envoyé avec succès !");
                    setMail("");
                    setUserName("");
                    setMessage("");
                    // alert('Email sent successfully!');
                  } catch (error) {
                    setIsLoading(false);
                    toast.error(
                      "Erreur lors de l'envoi de l'e-mail. Veuillez réessayer"
                    );
                    console.error("Error sending email:", error.message);
                    // alert('Error sending email. Please try again.');
                  }
                }
              }}
              className=" bg-[#140083] cursor-pointer rounded-lg px-6 py-3 text-white font-bold  mt-2"
            >
              Envoyer
            </div>
          </div>

          <div className=" md:flex hidden md:flex-row flex-col my-2 px-5 py-2 text-[#140083] bg-gradient-to-b from-zinc-300 to-white rounded-2xl shadow ">
            <input
              type="text"
              value={message}
              onChange={(v) => setMessage(v.target.value)}
              placeholder="Votre message"
              className="flex-1 px-3 bg-transparent border-none focus:outline-none"
            />
            <div
              onClick={async () => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isValid = emailPattern.test(mail);
                if (mail === "") {
                  toast.error("Veuillez entrer votre email");
                } else if (userName === "") {
                  toast.error("S'il vous plaît entrez votre nom");
                } else if (message === "") {
                  toast.error("Veuillez entrer votre message");
                } else if (!isValid) {
                  toast.error("Veuillez entrer votre email correct");
                } else {
                  // toast.success("nice")
                  setIsLoading(true);

                  try {
                    // await axios.post(
                    // "https://mailapi.amanayhome.com/send-email/",
                    // {
                    await axios.post(
                      "http://localhost:3001/send-email/",
                      {
                        mail: mail,
                        username: userName,
                        message: message,
                      },
                      {
                        withCredentials: true, // Ensure CORS is enabled
                      }
                    );
                    setIsLoading(false);
                    toast.success("Votre message envoyé avec succès !");
                    setMail("");
                    setUserName("");
                    setMessage("");
                    // alert('Email sent successfully!');
                  } catch (error) {
                    setIsLoading(false);
                    toast.error(
                      "Erreur lors de l'envoi de l'e-mail. Veuillez réessayer"
                    );
                    console.error("Error sending email:", error.message);
                    // alert('Error sending email. Please try again.');
                  }
                }
              }}
              className=" bg-[#140083] cursor-pointer rounded-lg px-6 py-3 text-white font-bold "
            >
              Envoyer
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HomeScreen;
