import React, { useEffect } from "react";

import illustrationImage from "../../assets/images/illustration_image.png";
import expertiseIcon from "../../assets/icons/expertise_icon.png";
import performanceIcon from "../../assets/icons/performance_icon.png";
import quantityIcon from "../../assets/icons/quality_icon.png";

// import Aos from "aos";
// import "aos/dist/aos.css";

const listChoisir = [
  {
    image: expertiseIcon,
    title: "Expertise",
    content: [
      "Nous avons une vaste expérience dans le digital et la technologie de l’information.",
      "Nous avons travaillé avec des entreprises de toutes tailles et dans divers secteurs pour aider nos clients à atteindre leurs objectifs.",
    ],
  },
  {
    image: performanceIcon,
    title: "Performance",
    content: [
      "Pour nous, la focalisation sur les résultats est une priorité.",
      "Nous commençons par comprendre vos besoins et objectifs à travers une analyse approfondie. Nous créons ensuite un plan d'action personnalisé pour refléter votre image et valeurs.",
    ],
  },
  {
    image: quantityIcon,
    title: "Qualité",
    content: [
      "Notre engagement en faveur de la qualité et de la satisfaction client est notre fierté.",
      "Nous livrons des produits finis de qualité supérieure en conformité avec les attentes et les besoins de nos clients. Nous soutenons nos clients du début à la fin pour atteindre le succès mérité.",
    ],
  },
];

function Choisir() {
  useEffect(() => {
    // Aos.init();
  });
  return (
    <div className="w-full min-h-max relative">
      <div className="container mx-auto w-full">
        <div className="text-center text-[#140083] text-xl">Pourquoi</div>
        <div className="text-center text-[#FF0000] font-bold text-4xl mt-2 mb-5">
          Nous choisir
        </div>
        {/* items */}
        <div className="flex md:flex-row flex-col my-10 justify-center items-center">
          <div data-aos="fade-right" className="md:w-1/2 w-full my-3">
            <img
              className=" md:w-4/5 w-1/2 mx-auto "
              src={illustrationImage}
              alt=""
            />
          </div>
          <div
            data-aos="fade-left"
            className="md:w-1/2  flex flex-col justify-between "
          >
            {listChoisir.map((choix, index) => (
              <div
                key={index}
                className="flex flex-row border border-[#140083] rounded-xl py-3 bg-white justify-center items-center md:my-5 my-3 mx-5"
              >
                <div className="mx-5">
                  <img src={choix.image} alt="" />
                </div>
                <div className="flex-1">
                  <div className=" font-medium text-md">{choix.title}</div>
                  <div>
                    {choix.content.map((item) => (
                      <div className="md:text-xs text-xs ">{item}</div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choisir;
