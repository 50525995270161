import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
