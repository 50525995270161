import React, { useEffect } from "react";

import heroImage from "../../assets/images/hero_image.png";
import amanayLogo from "../../assets/icons/amanay_logo.png";
import aylinkLogo from "../../assets/icons/aylink_logo.png";
import ayskillLogo from "../../assets/icons/ayskill_logo.png";
import bmsLogo from "../../assets/icons/bms_logo.png";
import levoyageLogo from "../../assets/icons/levoyage_logo.png";
import nazaiLogo from "../../assets/icons/nazai_logo.png";
import travelLinkLogo from "../../assets/icons/travel_link_logo.png";

// import Aos from "aos";
// import "aos/dist/aos.css";

function Hero() {
  useEffect(() => {
    // Aos.init();
  });

  return (
    <div className="w-screen min-h-max  ">
      <div className="container mx-auto">
        <div className=" md:h-40 h-10"></div>
        <div className="flex md:flex-row flex-col-reverse justify-center items-center min-h-max">
          {/*  */}
          <div data-aos="fade-right" className="md:w-1/2 w-full">
            <div className="text-[#140083] md:text-5xl text-3xl mx-5 mb-10 md:mt-5 mt-10 md:text-left text-center ">
              Rejoignez la révolution digitale avec{" "}
              <span className="font-bold">des solutions innovantes</span> à la
              pointe de la technologie !
            </div>
          </div>
          {/*  */}
          <div data-aos="fade-left" className="md:w-1/2 w-2/3 mx-auto ">
            <img alt="" className="mx-auto " src={heroImage} />
          </div>
        </div>
      </div>
      <div className="container mx-auto md:mt-24 mt-16 mb-5 ">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          className="flex justify-between items-center mx-10  mt-5 bg-[#140083] bg-opacity-5 rounded-full overflow-x-auto px-10 py-5"
        >
          <img className="md:h-10 h-6 mx-3" alt="" src={aylinkLogo} />
          <img className="md:h-10 h-6 mx-3" alt="" src={ayskillLogo} />
          <img className="md:h-10 h-6 mx-3" alt="" src={travelLinkLogo} />
          <img className="md:h-10 h-6 mx-3" alt="" src={levoyageLogo} />
          <img className="md:h-10 h-6 mx-3" alt="" src={nazaiLogo} />
          <img className="md:h-10 h-6 mx-3" alt="" src={bmsLogo} />
          <img className="md:h-10 h-6 mx-3" alt="" src={amanayLogo} />
        </div>
      </div>
    </div>
  );
}

export default Hero;
