import React, { useState } from "react";

import logo from "../assets/icons/aynnaka_logo.png";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-screen fixed  z-40 ">
      <div className="container mx-auto md:px-0 px-5">
        <div className="flex justify-between items-center  bg-[#f4e9ef]  rounded-full">
          {/* logo */}
          <img alt="" src={logo} className=" md:h-20 h-16 ml-10" />
          {/* menu */}
          <div className=" items-center text-sm md:flex hidden">
            <a href="/">
              <div className="hover:text-[#FF0000] font-bold mx-5 cursor-pointer group">
                Accueil
              </div>
            </a>
            <a href="/#about-us">
              <div className="hover:text-[#FF0000] font-bold mx-5 cursor-pointer group">
                A propos
              </div>
            </a>
            <a href="/#our-services">
              <div className="hover:text-[#FF0000] font-bold mx-5 cursor-pointer group">
                Nos services
              </div>
            </a>
            <a href="/#temoignages">
              <div className="hover:text-[#FF0000] font-bold mx-5 cursor-pointer group">
                Témoignages
              </div>
            </a>
          </div>
          {/* contact */}
          <a href="/#contact-us">
            <div className="px-5 text-xs text-white bg-[#140083] py-4 mx-5 rounded-full md:flex hidden">
              Contactez-nous
            </div>
          </a>
          {/* menu */}
          <div
            className="md:hidden block mx-10 cursor-pointer"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-7 h-7 text-[#140083] hover:text-[#FF0000]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
          <div
            className={`bg-white overflow-hidden  fixed z-50 top-0  h-max right-0 duration-500 transition-all ${
              isOpen ? "w-full" : "w-0"
            }`}
          >
            <div className="w-screen flex justify-between px-3 bg-white">
              <svg
                onClick={() => setIsOpen(false)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-10 h-10 text-[#140083] m-5 cursor-pointer hover:text-[#FF0000]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
              <a onClick={() => setIsOpen(false)} href="/">
                <img className="h-20" alt="nazai studio" src={logo} />
              </a>
            </div>
            <div className="w-full my-8 flex flex-col items-center">
              <a
                onClick={() => setIsOpen(false)}
                className="mx-3 font-bold my-3 text-md hover:text-[#FF0000] text-black"
                href="/"
              >
                <div>Accueil</div>
              </a>
              <a
                onClick={() => setIsOpen(false)}
                className="mx-3 font-bold my-3 text-md hover:text-[#FF0000] text-black"
                href="/#about-us"
              >
                <div> A propos</div>
              </a>
              <a
                onClick={() => setIsOpen(false)}
                className="mx-3 font-bold my-3 text-md hover:text-[#FF0000] text-black"
                href="/#our-services"
              >
                <div>Nos services</div>
              </a>
              <a
                onClick={() => setIsOpen(false)}
                className="mx-3 font-bold my-3 text-md hover:text-[#FF0000] text-black"
                href="/#temoignages"
              >
                <div>Témoignages</div>
              </a>

              <a
                onClick={() => setIsOpen(!isOpen)}
                className="mx-3 font-bold my-3 text-md hover:text-[#FF0000] text-black"
                href="/#contact-us"
              >
                <div>Contactez-nous</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
