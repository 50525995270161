import React, { useEffect } from "react";

import aboutImage from "../../assets/images/about_image.png";
import dots from "../../assets/icons/dots.png";
// import Aos from "aos";
// import "aos/dist/aos.css";
function About() {
  useEffect(() => {
    // Aos.init();
  });
  return (
    <div className="w-screen min-h-max" id="about-us">
      <div className="container mx-auto">
        <div className=" md:h-24 h-16"></div>
        <div className="flex md:flex-row flex-col justify-center items-center min-h-max">
          {/*  */}
          <div data-aos="fade-right" className="md:w-1/2 w-2/3 mx-auto ">
            <img alt="" className="mx-auto " src={aboutImage} />
          </div>
          {/*  */}
          <div data-aos="fade-left" className="md:w-1/2  mt-10 mx-5">
            <div className="text-[#140083] text-3xl md:text-left text-center font-bold">
              A propos de <span className="text-[#FF0000]">Nous</span>
            </div>
            <div className="text-[#140083] text-base my-5 md:text-left text-center">
              Aynnaka est une agence digitale innovante à Marrakech au Maroc.
              Constituée d’une équipe jeune, créative et déterminée qui se
              consacre à faciliter la digitalisation de votre entreprise. Nous
              honorons notre engagement à chaque étape de votre projet pour vous
              aider à atteindre la visibilité et l'accessibilité en ligne que
              vous souhaitez.
            </div>
            <div className="w-full relative md:flex hidden justify-end items-center">
              <img alt="" src={dots} className="mx-10" />
            </div>
          </div>
        </div>
        <div className=" md:h-24 h-16"></div>
      </div>
    </div>
  );
}

export default About;
